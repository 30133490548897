import React from "react";
import Header from "../Header";
import Footer from "../Footer";

export default function Layout(props) {
    return (
        <React.Fragment>
        <div className="container-layout">
            <div className="container-main">
                <Header />
                <div className="content">{props.children}</div>   
                <Footer/>
            </div>
        </div>
        </React.Fragment>
    )
}