import React from "react";
import logo from "../assets/images/logo.png";
import iconRetangulo from "../assets/icons/retangulo.svg";
import "../assets/styles/header.scss";
import { Row, Col } from "react-bootstrap";

export default function Header() {
  return (
    <div className="container-header">
      <div className="content-header">
        <Row>
          <Col sm={12} lg={9} md={12} xl={9}>
            <div id="brand">
              <img src={logo} alt="Logo Alucom" className="logo" />
            </div>
            <h1 className="title-list">Locamos:</h1>
            <div className="list-equipaments d-flex align-items-start justify-content-start">
              <ul>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Computadores e Notebooks
                </li>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  TV's e Vídeo Wall (4 TVs)
                </li>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Roteadores/Switch
                </li>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Impressoras/Multifuncionais Laser e Jato de tinta
                </li>
              </ul>
              <ul>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Data Show
                </li>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Plotters
                </li>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Tablets
                </li>
                <li>
                  <img
                    src={iconRetangulo}
                    alt="Icone Equipamento"
                    className="p-1"
                  />
                  Impressoras de pulseiras Hospitalares
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} sm={0} md={0} xl={3} className="col-circles">
            <div className="cirles-content">
              <div className="circle-computer"></div>
              <div className="circle-notebook"></div>
              <div className="circle-tablet"></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
