import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/footer.scss";
import iconInstagram from "../assets/icons/instagram-light.svg";
import iconLinkedin from "../assets/icons/linkedin-light.svg";
import iconPhone from "../assets/icons/phone-light.svg";
import iconWpp from "../assets/icons/whatsapp-light.svg";
import iconEmail from "../assets/icons/email-light.svg";
import iconLocation from "../assets/icons/location-light.png";
import iconFacebook from "../assets/icons/facebook-light.svg";
import CookieConsent from "react-cookie-consent";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-footer">
        <div className="row">
          <div className="footer-col">
            <h4>Contatos</h4>
            <div className="social-links">
              <Link
                target="_blank"
                to={{ pathname: "https://www.facebook.com/alucomcomputadores" }}
              >
                <img
                  src={iconFacebook}
                  alt="Icone Facebook"
                  className="icon-social"
                />
              </Link>
              <Link
                target="_blank"
                to={{ pathname: "https://www.instagram.com/alucomti/" }}
              >
                <img
                  src={iconInstagram}
                  alt="Icone Instagram"
                  className="icon-social"
                />
              </Link>
              <Link>
                <img
                  src={iconLinkedin}
                  alt="Icone Linkedin"
                  className="icon-social"
                />
              </Link>
              <Link
                target="_blank"
                to={{
                  pathname: "whatsapp://send?phone=+558598814-6081",
                }}
              >
                <img
                  src={iconWpp}
                  alt="Icone WhatsApp"
                  className="icon-social"
                />
              </Link>
              <Link target="_blank" to={{ pathname: "tel:+558593262-3191" }}>
                <img
                  src={iconPhone}
                  alt="Icone WhatsApp"
                  className="icon-social"
                />
              </Link>
              <Link
                target="_blank"
                to={{
                  pathname: "mailto:comercial@alucom.com.br",
                }}
              >
                <img
                  src={iconEmail}
                  alt="Icone E-mail"
                  className="icon-social"
                />
              </Link>
            </div>
            <div className="social-contacts">
              <Link
                target="_blank"
                to={{
                  pathname: "mailto:comercial@alucom.com.br",
                }}
              >
                <img
                  src={iconEmail}
                  alt="Icone Email"
                  className="icon-contacts"
                />
                comercial@alucom.com.br
              </Link>
              <Link
                target="_blank"
                to={{
                  pathname: "mailto:atendimentoalucom@gmail.com",
                }}
              >
                <img
                  src={iconEmail}
                  alt="Icone Email"
                  className="icon-contacts"
                />
                atendimentoalucom@gmail.com
              </Link>
              <Link target="_blank" to={{ pathname: "tel:+558599180-6129" }}>
                <img
                  src={iconWpp}
                  alt="Icone Wpp Comercial"
                  className="icon-contacts"
                />
                (85) 99180-6129 (Orçamento)
              </Link>
              <Link target="_blank" to={{ pathname: "tel:+558598814-6081" }}>
                <img
                  src={iconWpp}
                  alt="Icone Wpp Atendimento"
                  className="icon-contacts"
                />
                (85) 98814-6081 (Atendimento)
              </Link>
              <Link target="_blank" to={{ pathname: "tel:+558593262-3191" }}>
                <img
                  src={iconPhone}
                  alt="Icone Telefone"
                  className="icon-contacts"
                />
                (85) 3262-3191 ou 0800 285 2165
              </Link>
            </div>
          </div>
          <div className="footer-col">
            <h4 className="title-categoria">Categorias</h4>
            <ul className="list-items">
              <li>Computadores</li>
              <li>Notebooks</li>
              <li>Retroprojetores</li>
              <li>Plotters</li>
              <li>Impressoras</li>
              <li>Impressoras de pulseiras</li>
              <li>hospitalares</li>
              <li>Switch</li>
              <li>Roteadores</li>
              <li>Tablets</li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Onde estamos?</h4>
            <ul className="list-address">
              <li>
                <img
                  src={iconLocation}
                  alt="Icone de localização"
                  className="icon-address"
                />
              </li>
              <li>Rua Riachuelo, 40 - Papicu</li>
              <li>CEP: 60175-205 Fortaleza - Ceará</li>
            </ul>
          </div>
        </div>
        <p>&copy; Alucom - 2024</p>
        <CookieConsent
          location="bottom"
          style={{ textAlign: 'center' }}
          buttonStyle={{ background: '#FFFFFF', fontSize: '16px' }}
          buttonText="Ok, entendi!"
        >ALUCOM não coleta cookies. Veja a nossa <a href="/privacy">política de privacidade</a> para mais informações.</CookieConsent>
      </div>
    </footer>
  );
}
