import React, { useState } from "react";
import "../../assets/styles/home.scss";
import { Button, Card } from "react-bootstrap";
import Mascaras from "../../utils/masks";
import { atendimentoService } from "../../services/AtendimentosServices";
import { ToastError, ToastSuccess, ToastWarning } from "../Toast/Toast";

export default function FormAtendimento() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [descricao, setDescricao] = useState("");
  const [fieldsErros, setFieldErros] = useState({});

  const enabled =
    nome.length > 0 &&
    email.length > 0 &&
    telefone.length > 0 &&
    descricao.length > 0;

  function resetField() {
    setNome("");
    setEmail("");
    setTelefone("");
    setDescricao("");
  }

  async function cadastrarAtendimento(e) {
    e.preventDefault();
    let data = {
      nome: nome,
      email: email,
      telefone: telefone,
      descricao: descricao,
    };

    await atendimentoService
      .salvarAtendimento(data)
      .then((response) => {
        ToastSuccess(response.data.message);
        resetField();
      })
      .catch((erro) => {
        console.log(erro);
        if (erro.response) {
          setFieldErros(erro.response.data.data);
          Object.keys(fieldsErros).forEach((field) => {
            const erro = field.toUpperCase() + " " + fieldsErros[field];
            ToastWarning(erro);
          });
        } else if (erro.request) {
          console.log(erro.request);
          ToastWarning("Ocorreram erros ao enviar sua solicitação.");
        } else {
          console.log(erro);
          ToastError("Ocorreram erros ao enviar sua solicitação.");
        }
      });
  }
  return (
    <Card className="card-shadow rounded">
      <Card.Header className="card-header">
        <h2 className="text-center text-white">Abra um Chamado</h2>
      </Card.Header>
      <Card.Body>
        <div className="form">
          <p className="text-form">Abra um chamado para o suporte técnico</p>
          <form method="POST" action="" onSubmit={cadastrarAtendimento}>
            <label htmlFor="nome">Nome</label>
            <input
              type="text"
              name="nome"
              id="nome"
              required
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <label htmlFor="email">E-mail</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="telefone">Telefone</label>
            <input
              type="text"
              name="telefone"
              id="telefone"
              required
              value={telefone}
              onChange={(ev) =>
                setTelefone(Mascaras.maskPhone(ev.target.value))
              }
            />
            <label htmlFor="descricao">Descrição</label>
            <textarea
              name="descricao"
              id="descricao"
              cols="30"
              rows="10"
              required
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            ></textarea>
            <span className="message">
              Campo deve ter no mínimo 20 caracteres
            </span>
            <div className="d-flex  justify-content-end align-items-center ">
              <Button
                className="btn-send mt-4"
                disabled={!enabled}
                type="submit"
              >
                Enviar
              </Button>
            </div>
          </form>
        </div>
      </Card.Body>
    </Card>
  );
}
