import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import HomePage from "./views/pages/HomePage";
import LayoutWrapper from "./components/layout/LayoutWrapper";
import history from "./history";
import privacy from "./privacy";

export default function ScreensSwitch() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={LayoutWrapper(HomePage)}></Route>
        <Route path="/privacy" component={privacy}></Route>
      </Switch>
    </Router>
  );
}
