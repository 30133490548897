import React from "react";
import "../../assets/styles/home.scss";
import { Row, Col } from "react-bootstrap";
import FormAtendimento from "../../components/FormAtendimento/FormAtendimento";
import FormOrcamento from "../../components/FormOrcamento/FormOrcamento";
import Loader from "../../components/Loader/Loader";

export default function HomePage() {
  return (
    <div className="container-home">
      <Loader />
      <Row>
        <Col lg={6} md={6} sm={6} xl={6} className="col-rent ml-auto">
          <FormOrcamento />
        </Col>

        <Col lg={6} md={6} sm={6} xl={6} className="col-called  ml-auto">
          <FormAtendimento />
        </Col>
      </Row>
    </div>
  );
}
