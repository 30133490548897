import React from "react";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.Fragment>
      <ToastContainer theme="colored" toastStyle={{ width: "420px" }} />
      <Routes />
    </React.Fragment>
  );
}

export default App;
