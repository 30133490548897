import { toast } from "react-toastify";

export function ToastErrorGet() {
  toast.error("Erro ao executar essa função", {
    position: "top-center",
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: true,
    icon: true,
  });
}

export function ToastError(text) {
  toast.error(text, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    icon: true,
  });
}

export function ToastSuccess(message) {
  toast.success(message, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: true,
    icon: true,
    success: message,
  });
}

export function ToastWarning(text) {
  toast.warning(text, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    progress: 0,
  });
}
