import React from "react";
import Layout from "./Layout";

const LayoutWrapper = (Children) =>
  function AnimatedWrapper(props) {
    return (
      <Layout>
        <Children {...props}></Children>
      </Layout>
    );
  };

export default LayoutWrapper;
