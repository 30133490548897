import React from 'react';
import { Link } from 'react-router-dom';

const privacy = () => {
    return (
        <div>
            <h1><center>POLÍTICA DE PRIVACIDADE</center></h1>
            <p><Link to="/">retornar a página inicial</Link></p>
            <span>Por favor, leia esta Política de Privacidade e Proteção de Dados Pessoais cuidadosamente para entender nossas políticas e práticas sobre seus Dados Pessoais e como o tratamento destes dados é realizado.</span>
            <p><span>Esta Política se aplica a indivíduos (pessoas naturais) que interagem com serviços da ALUCOM como consumidores (“Você”). Esta Política explica como seus Dados Pessoais são coletados, usados e divulgados pela ALUCOM (referido nessa política como “Nós”, “Conosco”). Ela também informa como você pode acessar e atualizar seus Dados Pessoais e tomar certas decisões sobre como seus Dados Pessoais são utilizados.</span></p>
            <h3><center>Introdução</center></h3>
            <p>A ALUCOM está comprometida com a proteção de dados e informações pessoais que são compartilhados pelos usuários.</p>
            <p>Essa política define como os dados são protegidos nos processos de coleta, registro, armazenamento, uso, compartilhamento, enriquecimento e eliminação, para além da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados).</p>
            <h3><center>Sobre de Dados Pessoais de Crianças e Adolescentes</center></h3>
            <p>A ALUCOM não solicita, coleta, processa, armazena ou compartilha, conscientemente, dados pessoais de crianças e adolescentes menores de idade. Se descobrirmos a ocorrência de qualquer tipo de manuseio dos referidos dados, de forma não-intencional, removeremos os dados pessoais daquela criança ou adolescente de nossos registros no menor tempo possível.</p>
            <p>Entretanto, a ALUCOM pode coletar dados pessoais de crianças e adolescentes diretamente dos seus pais ou responsáveis legais, e com consentimento explícito, e de acordo com as regras da legislação vigente.</p>
            <h3><center>Sobre o uso de Cookies, arquivos de registros (logs) e similares</center></h3>
            <p>Cookies são pequenos arquivos de texto que podem ser colocados no seu computador ou dispositivo portátil por sites ou serviços na web que você utiliza. São utilizados para garantir o bom funcionamento de sites e demais serviços online, assim como para fornecer informações aos proprietários do site ou serviço online.</p>
            <p>Cookies são utilizados para melhorar o uso e a funcionalidade dos sites e demais serviços na web compatíveis com a empresa e entender melhor como nossos visitantes utilizam os sites e serviços online, bem como as ferramentas e serviços por eles oferecidos. Os Cookies facilitam o processo de adaptar os sites da empresa às suas necessidades pessoais, facilitando sempre que possível o seu uso, receber feedback da satisfação dos consumidores.</p>
            <p>A ALUCOM não coleta cookies.</p>
            <h3><center>Reclamações e dúvidas</center></h3>
            <p>Caso tenha qualquer dúvida relacionada com o tratamento dos seus dados pessoais e com os direitos que lhe são conferidos pela legislação aplicável e, em especial, referidos nesta Política, poderá acionar a ALUCOM através do e-mail: <a href="mailto:dpo@alucom.com.br">dpo@alucom.com.br</a></p>
            <h3><center>Alterações em nossa Política de Privacidade</center></h3>
            <p>Sempre que a ALUCOM decidir mudar a forma que tratamos seus Dados Pessoais, esta Política será atualizada. Nos reservamos o direito de fazer alterações às nossas práticas e a esta Política a qualquer tempo, desde que mantida a conformidade com a legislação vigente.</p>
            <p>Recomendamos que você a acesse frequentemente, ou sempre que tiver dúvidas, para ver quaisquer atualizações ou mudanças à nossa Política de Privacidade.</p>
            <h3><center>Como entrar em contato</center></h3>
            <p>Você pode entrar em contato para:</p>
            <span>
                Fazer perguntas ou comentários sobre esta Política e nossas práticas de privacidade e proteção de Dados Pessoais;

                Fazer uma reclamação;

                Confirmação da existência de tratamento de seus Dados Pessoais;

                Obter informações sobre como acessar seus Dados Pessoais;

                Realizar a correção de dados pessoais incompletos, inexatos ou desatualizados;

                Obter informações sobre a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na legislação vigente;

                Obter informações sobre a portabilidade dos seus dados pessoais a outro fornecedor de serviço ou produto, mediante requisição expressa, em conformidade com a legislação vigente;

                Solicitar a eliminação dos dados pessoais tratados com o seu consentimento, excetuando-se as hipóteses previstas na legislação vigente;

                Solicitar detalhes das entidades públicas e privadas com as quais realizamos o compartilhamento de seus Dados Pessoais;

                Obter informações sobre a possibilidade de não fornecer consentimento e sobre as consequências dessa negativa;

                Realizar a revogação do consentimento para o tratamento dos seus Dados Pessoais, excetuando-se as hipóteses previstas na legislação vigente;

                Demais direitos do titular dos dados pessoais, conforme legislação vigente.
            </span>
            <p>Para isso, solicitamos que Você entre em contato conosco usando os seguintes canais:</p>
            <h3><center>Encarregado pelo tratamento dos dados</center></h3>
            <p>Disponibilizamos os dados para contato com nosso Encarregado pelo Tratamento de Dados, para qualquer esclarecimento e informação:</p>
            <p>Nome: Imna Paiva Dantas Maciel</p>
            <p>E-mail: <a href="mailto:dpo@alucom.com.br">dpo@alucom.com.br</a></p>
            <p>As comunicações realizadas entre a ALUCOM e o titular através dos meios de contato cadastrados serão consideradas verdadeiras entre as partes, como legítimos instrumentos válidos para manifestação de suas vontades.</p>
            <p>
                A ALUCOM receberá, investigará e responderá, dentro de um prazo considerado razoável, qualquer solicitação ou reclamação sobre a forma como Nós tratamos seus Dados Pessoais, incluindo reclamações sobre desrespeito aos seus direitos sob as leis de privacidade e proteção de Dados Pessoais vigentes.
            </p>
            <h3><center>Sobre a Política de Privacidade e Proteção de Dados</center></h3>
            <p>Esta política visa fornecer aos leitores informações claras e objetivas. No entanto, se ainda persistir dúvidas ou reclamações sobre esse texto ou sobre a forma como tratamos os seus dados, pedimos que você entre em contato conosco para resolver suas necessidades antes de tomar qualquer medida administrativa ou judicial.</p>
            <p>Esta política de privacidade será habitualmente revisada e atualizada com base na dinâmica dos processos existentes, agregando novidades e fazendo ajustes de acordo com as exigências legais, tendo sempre como objetivo proteger a privacidade dos titulares de dados pessoais e garantir o uso legal de suas informações.</p>
            <p>A presente versão desta Política de Privacidade e Proteção de Dados foi revisada em dezembro de 2023.</p>
        </div>
    );
}

export default privacy;