import React, { useState } from "react";
import "../../assets/styles/home.scss";
import Mascaras from "../../utils/masks";
import { Button, Card } from "react-bootstrap";
import { orcamentoService } from "../../services/OrcamentosService";
import {
  ToastError,
  ToastSuccess,
  ToastWarning,
} from "../../components/Toast/Toast";

export default function FormOrcamento() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [locacao, setLocacao] = useState("");
  const [fieldsErros, setFieldErros] = useState({});
  // const [showLoading, setShowLoading] = useState(false);

  const enabled =
    nome.length > 0 &&
    email.length > 0 &&
    telefone.length > 0 &&
    empresa.length > 0 &&
    locacao.length > 0;

  function resetField() {
    setNome("");
    setEmail("");
    setTelefone("");
    setEmpresa("");
    setLocacao("");
  }
  async function cadastrarOrcamento(e) {
    e.preventDefault();
    let data = {
      nome: nome,
      email: email,
      telefone: telefone,
      empresa: empresa,
      locacao: locacao,
    };
    await orcamentoService
      .salvarOrcamento(data)
      .then((response) => {
        ToastSuccess(response.data.message);
        resetField();
      })
      .catch((erro) => {
        console.log(erro);
        if (erro.response) {
          setFieldErros(erro.response.data.data);
          Object.keys(fieldsErros).forEach((field) => {
            const erro = field.toUpperCase() + " " + fieldsErros[field];
            ToastWarning(erro);
          });
        } else if (erro.request) {
          ToastWarning("Ocorreram erros ao enviar sua solicitação.");
        } else {
          console.log("erro", erro);
          ToastError("Ocorreram erros ao enviar sua solicitação.");
        }
      });
  }
  return (
    <Card className="card-shadow rounded">
      <Card.Header className="card-header">
        <h2 className="text-center text-white">Faça um Orçamento </h2>
      </Card.Header>
      <Card.Body>
        <div className="form">
          <p className="text-form">
            Peça seu orçamento e em instantes retornamos!
          </p>
          <form method="POST" action="" onSubmit={cadastrarOrcamento}>
            <label htmlFor="nome">Nome</label>
            <input
              type="text"
              name="nome"
              id="nome"
              required
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <label htmlFor="empresa">E-mail</label>
            <input
              type="email"
              name="email"
              id="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="telefone">Telefone</label>
            <input
              type="text"
              name="telefone"
              id="telefone"
              required
              value={telefone}
              onChange={(e) => setTelefone(Mascaras.maskPhone(e.target.value))}
            />
            <label htmlFor="empresa">Empresa</label>
            <input
              type="text"
              name="empresa"
              id="empresa"
              required
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
            />
            <label htmlFor="locacao">O que você quer locar?</label>
            <textarea
              name="locacao"
              id="locacao"
              cols="30"
              rows="8"
              required
              value={locacao}
              onChange={(e) => setLocacao(e.target.value)}
            ></textarea>
            <span className="message">
              Campo deve ter no mínimo 20 caracteres
            </span>
            <div className="d-flex justify-content-end  align-items-center">
              {/* {showLoading ? (
                <Button className="btn-send" disabled={!enabled} type="submit">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Enviando...</span>
                </Button>
              ) : ( */}
              <Button className="btn-send" disabled={!enabled} type="submit">
                Enviar
              </Button>
              {/* )} */}
            </div>
          </form>
        </div>
      </Card.Body>
    </Card>
  );
}
