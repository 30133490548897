import React, { useState, useEffect } from "react";
import "../../assets/styles/loader.scss";

export default function Loader() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return isLoading ? (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  ) : (
    ""
  );
}
